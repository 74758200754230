<template>
  <div class="fullwidth align-left" @click="hideUserOptions()">
    <div class="content-header flex-row flex-row-between">
      <h1>Listado de tipos de cobro de lugares<span class="cantidad-resultados"> - Resultados: {{tiposCobrosLugaresFiltrados.length}}</span></h1>

      <div class="content-header-buttons flex-row">
        <div class="content-header-filter flex-row" @click="$emit('mostrarFiltro')">
          <i class="icon-panel"></i>
          <span>Filtrar</span>
        </div>
        <button class="btn-primary shadow animated" @click="$emit('crear')">Agregar nuevo</button>
      </div>
    </div>

    <Chips
      :chips="chips"
      @removeChip="quitarFiltro"></Chips>

    <Table
      :options='tableOptions'
      @ver="ver"
      @editar="editar"
      @cambiarEstado="cambiarEstado"></Table>
  </div>
</template>

<script>
import ajax from '@/ajax'
import { EventBus } from '@/event-bus.js'
import Table from '../table'
import Chips from '../chips'
import ConstantsHelper from '@/ConstantsHelper'

export default {
  name: 'listado',
  components: {
    Table,
    Chips
  },
  data () {
    return {
      tiposCobrosLugares: [],
      tiposCobrosLugaresFiltrados: [],
      filtros: {
        text: '',
        estado: '1'
      },

      chips: {
        text: {
          show: false,
          indicador: 'Palabra Clave',
          chip: '',
          title: 'Eliminar este filtro'
        },
        estado: {
          show: true,
          indicador: 'Estado',
          chip: 'Activos',
          title: 'Eliminar este filtro'
        }
      },

      tableOptions: {
        columns: [
          {
            name: 'Nombre',
            width: 90,
            rowProperty: 'nombre',
            hideMobile: false
          }
        ],
        options: true,
        rows: [],
        buttons: [
          {
            name: 'EDITAR',
            fn: 'editar'
          }
        ]
      }
    }
  },
  methods: {
    cambiarEstado (data) {
      let tipoCobroLugar = data.obj
      let index = data.index
      let action = data.action
      const actionName = (action === 1) ? 'Activará' : 'Desactivará'
      if (confirm(`Se ${actionName} el tipo de cobro de lugar. ¿Desea continuar?`)) {
        this.$emit('loading', true)

        const nuevoEstado = (tipoCobroLugar.state === 1) ? 0 : 1

        const nuevoTipoLugar = {
          id: tipoCobroLugar.id,
          nombre: tipoCobroLugar.nombre,
          estado: nuevoEstado
        }

        ajax('PUT', ConstantsHelper.getURLs().v1 + 'lugares-cobros-tipos.php', { tipoCobroLugar: nuevoTipoLugar, action: 'cambiarEstado', usuarioLogueado: JSON.parse(localStorage.getItem('usuarioGalponLogueado')) })
          .then((response) => {
            let type = 'error'
            if (response.status === 1) {
              this.tiposCobrosLugaresFiltrados[index].state = nuevoEstado
              this.ordenarPorEstado()
              this.filtrar()
              type = 'correct'
            }
            EventBus.$emit('generateToast', {
              message: response.message,
              type: type
            })

            this.$emit('loading', false)
          })
          .catch(() => {
            this.$emit('loading', false)
          })
      }
    },

    filtrar () {
      this.tiposCobrosLugaresFiltrados = this.tiposCobrosLugares
      this.tiposCobrosLugaresFiltrados = this.filtrarText()
      this.tiposCobrosLugaresFiltrados = this.filtrarEstado()

      this.ordenarPorEstado()
    },
    filtrarText () {
      return this.tiposCobrosLugaresFiltrados.filter((tcl) => {
        return (tcl.nombre.toLowerCase().indexOf(this.filtros.text) !== -1)
      })
    },
    filtrarEstado () {
      return this.tiposCobrosLugaresFiltrados.filter((tcl) => {
        return (this.filtros.estado === '' || tcl.state === parseInt(this.filtros.estado))
      })
    },
    filtroRapido (text) {
      this.filtros.text = text
      this.actualizarChips()
      this.filtrar()
    },

    quitarFiltro (field) {
      this.filtros[field] = ''
      this.filtrar()

      if (field === 'text') {
        EventBus.$emit('actualizarValorBuscado', '')
      }

      EventBus.$emit('actualizarFiltros', this.filtros)
    },

    ordenarPorEstado () {
      this.tiposCobrosLugaresFiltrados.sort((a, b) => {
        if (a.state === 0) {
          return 1
        }
        if (b.state === 0) {
          return -1
        }
        return a.nombre - b.nombre
      })
    },

    parseTipoCobroLugar (tipoCobroLugar) {
      return {
        id: tipoCobroLugar.id,
        nombre: tipoCobroLugar.tipo,
        state: tipoCobroLugar.state,
        mostrarMenu: false
      }
    },
    hideUserOptions () {
      for (let tipoCobroLugar of this.tiposCobrosLugares) {
        tipoCobroLugar.mostrarMenu = false
      }
    },
    ver (tipoCobroLugar) {
      this.$emit('ver', tipoCobroLugar)
    },
    editar (tipoCobroLugar) {
      this.$emit('editar', JSON.parse(JSON.stringify(tipoCobroLugar)))
    },

    actualizarChips () {
      this.chips.text.chip = this.filtros.text
      this.chips.text.show = this.filtros.text !== ''

      this.chips.estado.chip = (this.filtros.estado === '1') ? 'Activos' : 'Desactivados'
      this.chips.estado.show = this.filtros.estado !== ''
    },

    actualizarAltoTabla () {
      let header = document.getElementsByClassName('main-content-header')[0]
      let contentHeader = document.getElementsByClassName('content-header')[0]
      let chipsContainer = document.getElementsByClassName('chips-container')[0]

      if (header !== undefined && contentHeader !== undefined && chipsContainer !== undefined) {
        EventBus.$emit('actualizarAltoTabla', [
          header.getBoundingClientRect().height,
          contentHeader.getBoundingClientRect().height,
          chipsContainer.getBoundingClientRect().height
        ])
      }
    }
  },
  mounted () {
    EventBus.$on('tipoCobroLugarAgregado', (tipoUsuario) => {
      this.tiposCobrosLugares.push(tipoUsuario)
      this.filtrar()
    })
    EventBus.$on('tipoCobroLugarEditado', (tipoLugar) => {
      const index = this.tiposCobrosLugares.findIndex(tl => tl.id === tipoLugar.id)
      this.tiposCobrosLugares[index] = tipoLugar
      this.filtrar()
    })
    EventBus.$on('filtroRapidoTiposcobroslugares', this.filtroRapido)
    EventBus.$on('filtrar', (filtroData) => {
      this.filtros = filtroData
      this.actualizarChips()
      this.filtrar()
    })
    this.$emit('loading', true)

    ajax('GET', ConstantsHelper.getURLs().v1 + 'lugares-cobros-tipos.php?estado=%')
      .then((response) => {
        let type = 'error'
        if (response.code === 1) {
          response.data.forEach(tcl => {
            this.tiposCobrosLugares.push(this.parseTipoCobroLugar(tcl))
          })
          this.filtrar()
          this.$emit('loading', false)
          type = 'correct'
        } else {
          EventBus.$emit('generateToast', {
            message: response.response,
            type: type
          })
        }
      })
  },
  watch: {
    tiposCobrosLugares () {
      this.actualizarAltoTabla()
    },
    tiposCobrosLugaresFiltrados () {
      this.tableOptions.rows = this.tiposCobrosLugaresFiltrados
    }
  }
}
</script>

<style>

</style>
